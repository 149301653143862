@use 'global/colors';
@import 'oranum_colors';
@import '../../../scss/mixin/util.mixin';

:root {
	@include rgbfy('header-bg', $color-primary);
	@include rgbfy('header-height', 5.2rem);
	@include rgbfy('header-border-bottom', none);
	@include rgbfy('account-selector--icon', #{colors.get(yellow, 500)});
    @include rgbfy('account-selector--icon-unselected', #{colors.get(yellow, 500)});
	@include rgbfy('account-settings--list-active-background', $color-background-container);
	@include rgbfy('account-settings--list-inactive-background', $color-primary-1);
	@include rgbfy('account-settings--list-active-text', $color-primary);
	@include rgbfy('account-settings--list-border', #{colors.get(neutral, 100, 0.1)});
	@include rgbfy('account-settings--list-text', $color-primary-4);
	@include rgbfy('account-settings--list-text-hover', $color-primary);
	@include rgbfy('account-settings--menu-background', $color-primary-1);
	@include rgbfy('account-settings--note-text', $color-text);
	@include rgbfy('account-settings--overlay-header', $color-background-container);
	@include rgbfy('account-settings--sensitive-data-confirmation-header-icon', $color-text-muted);
	@include rgbfy('account-settings--info-box-color', $color-background-container);
	@include rgbfy('account-settings--label', $color-primary);
	@include rgbfy('account-settings--text', $color-text);
	@include rgbfy('alertbox--alert-color', $color-warning);
	@include rgbfy('alertbox--alert-strong', $color-text-inversed);
	@include rgbfy('alertbox--alert-link', colors.get(blue, 400));
	@include rgbfy('background', #{colors.get(purple, 200)});
	@include rgbfy('box--border', $color-border);
	@include rgbfy('box--bottom-bg', $color-background-container);
	@include rgbfy('box--content-background', $color-background-container);
	@include rgbfy('box--header-background', $color-background-container);
	@include rgbfy('box--header-border', $color-border);
	@include rgbfy('box--header-text', $color-primary);
	@include rgbfy('box--info-bg', $color-background-widget);
	@include rgbfy('box--main-bg', $color-background-container);
	@include rgbfy('box--status-type--icon', $color-primary-3);
	@include rgbfy('box--status-type--gender', colors.get(purple, 700));
	@include rgbfy('box--status-type--person', inherit);
	@include rgbfy('box--message--alert', #{colors.get(pink, 400)});
	@include rgbfy('box--message--default', #{colors.get(neutral, 400)});
	@include rgbfy('box--message--info', #55ACEE);
	@include rgbfy('box--message--success', #{colors.get(alert, success_600)});
	@include rgbfy('box--message--warning', #{colors.get(yellow, 400)});
	@include rgbfy('box--notify-bg', #{colors.get(red, 600)});
	@include rgbfy('box--round-border', #{colors.get(neutral, 100)});
	@include rgbfy('box--secondary--bg', $color-background-widget);
	@include rgbfy('box--secondary--text', $color-primary);
	@include rgbfy('box--separator-text', $color-text-muted);
	@include rgbfy('box--shadow', #{colors.get(neutral, 900)});
	@include rgbfy('box--skip-bg', $color-background-widget);
	@include rgbfy('button--primary--shadow', transparent);
	@include rgbfy('button--primary--text', $button-primary-color);
	@include rgbfy('captcha-background', #{colors.get(neutral, 150)});
	@include rgbfy('card--default--background', rgba($color-primary, 0.8));
	@include rgbfy('card--default--number-active-shadow', transparent);
	@include rgbfy('card--default--number-active-text', $color-secondary);
	@include rgbfy('card--default--number-background', $color-secondary);
	@include rgbfy('card--default--number-bg-darker', $color-primary);
	@include rgbfy('card--active--number-border', $color-secondary);
	@include rgbfy('card--default--number-border', #{colors.get(neutral, 100)});
	@include rgbfy('card--default--number-bg-white', #{colors.get(neutral, 100)});
	@include rgbfy('card--default--number-text', #{colors.get(neutral, 100)});
	@include rgbfy('card--default--border-color', #{colors.get(neutral, 400)});
	@include rgbfy('card--default--link', $color-secondary);
	@include rgbfy('card--default--link-hover', #{colors.get(neutral, 100)});
	@include rgbfy('card--default--subtitle', $color-primary-2);
	@include rgbfy('card--default--title', #{colors.get(neutral, 100)});
	@include rgbfy('card--divider', $color-primary);
	@include rgbfy('category-selector--border', $color-primary-3);
	@include rgbfy('category-selector--border-hover', #{colors.get(yellow, 500)});
	@include rgbfy('category-selector--border-selected', #{colors.get(yellow, 500)});
	@include rgbfy('celebrity--gradient-bg', #{colors.get(red, 800)});
	@include rgbfy('celebrity--gradient-bg-from', #{colors.get(red, 400)});
	@include rgbfy('celebrity--gradient-bg-to', #{colors.get(alert, progress_500)});
	@include rgbfy('celebrity--money-section-heading', #{colors.get(red, 400)});
	@include rgbfy('celebrity--money-section-text', #{colors.get(neutral, 600)});
	@include rgbfy('channel--album-item-counter-text', $color-text-muted);
	@include rgbfy('channel--album-total-counter', #{colors.get(neutral, 400)});
	@include rgbfy('channel--box--action-text', $color-text-muted);
	@include rgbfy('channel--box--bg-gr', #{colors.get(red, 600)});
	@include rgbfy('channel--box--control-text', #{colors.get(neutral, 100)});
	@include rgbfy('channel--box--hover-title-bg', #{colors.get(red, 600)});
	@include rgbfy('channel--box--icon', #{colors.get(purple, 300)});
	@include rgbfy('channel--box--icon-hover', #{colors.get(purple, 300)});
	@include rgbfy('channel--box--icon-active', #{colors.get(neutral, 100)});
	@include rgbfy('channel--box--icon-alert', #{colors.get(yellow, 500)});
	@include rgbfy('channel--box--icon-alert-rejected', #{colors.get(yellow, 500)});
	@include rgbfy('channel--box--inactive-mask', #{colors.get(red, 500)});
	@include rgbfy('channel--box--inner-bg', $color-background);
	@include rgbfy('channel--box--inner-bg-hover', rgba($color-primary, 0));
	@include rgbfy('channel--box--inner-bg-hover-end', rgba($color-primary, 0.8));
	@include rgbfy('channel--box--inner-icon', #{colors.get(neutral, 100)});
	@include rgbfy('channel--box--inner-error-color', #{colors.get(neutral, 100)});
	@include rgbfy('channel--box--photo-upload-failed-color', #{colors.get(neutral, 100)});
	@include rgbfy('channel--box--inner-title-bg', #{colors.get(neutral, 400)});
	@include rgbfy('channel--box--inner-border', $color-primary-2);
	@include rgbfy('channel--box--input-placeholder-text', $color-primary-2);
	@include rgbfy('channel--box--input-text', $color-text-muted);
	@include rgbfy('channel--box--uploading-text', #{colors.get(neutral, 700)});
	@include rgbfy('channel--box--album-input-name', #{colors.get(neutral, 500)});
	@include rgbfy('channel--box--album-empty-bg', #{colors.get(neutral, 400)});
	@include rgbfy('channel--box--promo-ribbon-bg-from', #{colors.get(yellow, 400)});
	@include rgbfy('channel--box--promo-ribbon-bg-to', #DE9300);
	@include rgbfy('channel--box--promo-ribbon-text', #{colors.get(red, 700)});
	@include rgbfy('channel--box--rejected-bg', #{colors.get(red, 700)});
	@include rgbfy('channel--box--text2', $color-text-muted);
	@include rgbfy('channel--box--page-switch-bg', #{colors.get(neutral, 150)});
	@include rgbfy('channel--box--page-switch-border', #{colors.get(neutral, 300)});
	@include rgbfy('channel--box--album-folder-gradient-bg', #{colors.get(neutral, 900)});
	@include rgbfy('channel--box--text-error-color', #{colors.get(neutral, 100)});
	@include rgbfy('channel--folder-bg-rgb', #{colors.get(neutral, 900)});
	@include rgbfy('channel--comment-bg', $color-primary-1);
	@include rgbfy('channel--comment-border', $color-primary-2);
	@include rgbfy('channel--comment-input-bg', #{colors.get(neutral, 100)});
	@include rgbfy('channel--comment-remove-bg', $color-primary-2);
	@include rgbfy('channel--comment-border-color', $color-border);
	@include rgbfy('channel--comment-name', $color-primary);
	@include rgbfy('channel--comment-text', $color-text-muted);
	@include rgbfy('channel--comment-title-bg', $color-background);
	@include rgbfy('channel--comment-title-color', $color-text-muted);
	@include rgbfy('channel--creation-progress-bar-gr', #{colors.get(yellow, 500)});
	@include rgbfy('channel--creation-progress-bar-gr-darken', #e6b800);
	@include rgbfy('channel--creation-progress-bar-gr-done', #{colors.get(alert, success_600)});
	@include rgbfy('channel--creation-progress-bar-gr-done-darken', #{colors.get(alert, success_600)});
	@include rgbfy('channel--creation-progress-bar-inner-shadow', #{colors.get(red, 800)});
	@include rgbfy('channel--creation-progress-bg', $color-primary-4);
	@include rgbfy('channel--creation-progress-bs', #{colors.get(neutral, 900, 0.25)});
	@include rgbfy('channel--creation-progress-icon-border-color', #{colors.get(neutral, 900)});
	@include rgbfy('channel--creation-progress-success-span-bg', #{colors.get(neutral, 100)});
	@include rgbfy('channel--creation-progress-text', #{colors.get(neutral, 900)});
	@include rgbfy('channel--disabled--checkbox-shadow', #{colors.get(neutral, 300)});
	@include rgbfy('channel--disabled--inner-border', $color-primary-2);
	@include rgbfy('channel--disabled--notes-inner-bg', #{colors.get(red, 600)});
	@include rgbfy('channel--dropdown--bg', #{colors.get(neutral, 900)});
	@include rgbfy('channel--dropdown--bg-hover', #{colors.get(neutral, 850)});
	@include rgbfy('channel--dropdown--link', #{colors.get(neutral, 300)});
	@include rgbfy('channel--dropdown--link-hover', #{colors.get(neutral, 100)});
	@include rgbfy('channel--editor-header', #{colors.get(neutral, 100)});
	@include rgbfy('channel--flip-card-back-text', #{colors.get(pink, 500)});
	@include rgbfy('channel--flip-card-disabled-text', #{colors.get(pink, 600)});
	@include rgbfy('channel--folder-bg', $color-primary);
	@include rgbfy('channel--folder-overlay-text', #{colors.get(neutral, 100)});
	@include rgbfy('channel--gallery-info-text-shadow', #{colors.get(neutral, 900, 0.7)});
	@include rgbfy('channel--gallery-overlay-tools', #{colors.get(neutral, 100)});
	@include rgbfy('channel--info-bg', $color-background);
	@include rgbfy('channel--info-number', $color-primary-5);
	@include rgbfy('channel--info-text', $color-primary-4);
	@include rgbfy('channel--info-title', $color-primary);
	@include rgbfy('channel--interface-button-hover', #{colors.get(red, 800)});
	@include rgbfy('channel--landing-list', $color-background);
	@include rgbfy('channel--landing-title', $color-text-muted);
	@include rgbfy('channel--listpage-title', $color-primary);
	@include rgbfy('channel--listpage-text', $color-primary-4);
	@include rgbfy('channel--media-bar-overlay-bg',  #{colors.get(neutral, 900, 0.6)});
	@include rgbfy('channel--message-bg', #{colors.get(red, 500)});
	@include rgbfy('channel--note--overlay-bg', $color-primary);
	@include rgbfy('channel--note-info-text', $color-primary);
	@include rgbfy('channel--note-overlay-content-bg', $color-primary-1);
	@include rgbfy('channel--note-overlay-text', $color-primary);
	@include rgbfy('channel--overlay-bg', $color-primary-9);
	@include rgbfy('channel--overlay-text', #{colors.get(neutral, 100)});
	@include rgbfy('channel--playlist--bg', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--checkbox-label', transparent);
	@include rgbfy('channel--playlist--checkbox-shadow', #{colors.get(red, 700)});
	@include rgbfy('channel--playlist--duration', #{colors.get(neutral, 400)});
	@include rgbfy('channel--playlist--fade', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--frame', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--frame-shadow', #{colors.get(neutral, 500)});
	@include rgbfy('channel--playlist--helper-bg', transparent);
	@include rgbfy('channel--playlist--inner-gr', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--inner-gr-lighten', #{colors.get(neutral, 800)});
	@include rgbfy('channel--playlist--pager-gr', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--scrollbar-bg', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--scrollbar-bg-darken', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--scrollbar-gr-from', #{colors.get(neutral, 400)});
	@include rgbfy('channel--playlist--scrollbar-gr-to', #{colors.get(neutral, 500)});
	@include rgbfy('channel--playlist--shadow', #{colors.get(neutral, 500)});
	@include rgbfy('channel--playlist--time', #{colors.get(neutral, 500)});
	@include rgbfy('channel--playlist--top-msg-bg', #{colors.get(neutral, 850)});
	@include rgbfy('channel--playlist--top-msg-text', #{colors.get(neutral, 500)});
	@include rgbfy('channel--playlist--track-bar', #{colors.get(red, 800)});
	@include rgbfy('channel--playlist--track-bar-bg', #{colors.get(red, 800)});
	@include rgbfy('channel--playlist-close', #{colors.get(neutral, 500)});
	@include rgbfy('channel--playlist-pager', #{colors.get(neutral, 500)});
	@include rgbfy('channel--price', #{colors.get(yellow, 500)});
	@include rgbfy('channel--price-bg', #{colors.get(neutral, 900)});
	@include rgbfy('channel--price-settings-mask', #{colors.get(red, 800)});
	@include rgbfy('channel--price-settings-scroll-container-bg', #{colors.get(red, 800)});
	@include rgbfy('channel--price-settings-tooltip-bg', #{colors.get(neutral, 900, 0.5)});
	@include rgbfy('channel--title-free-highlite', #{colors.get(pink, 500)});
	@include rgbfy('channel--title-paid-highlite', #{colors.get(yellow, 500)});
	@include rgbfy('channel-info--bg', #{colors.get(neutral, 150)});
	@include rgbfy('channel-info--intro--background-icon', #{colors.get(red, 600)});
	@include rgbfy('channel--video-converting-bg', #{colors.get(neutral, 400)});
	@include rgbfy('channel--video-remaining-time', #{colors.get(neutral, 100)});
	@include rgbfy('channel--video-control-icon', #{colors.get(neutral, 100)});
	@include rgbfy('channel--video-progess-bar', #{colors.get(decorative, silver_400, 0.8)});
	@include rgbfy('channel--creation-progress-text', #{colors.get(neutral, 700)});
	@include rgbfy('channel--creation-progress-success-bg-1', #7BD4C4);
	@include rgbfy('channel--creation-progress-success-bg-2', #687BC4);
	@include rgbfy('common--alert', #{colors.get(alert, progress_500)});
	@include rgbfy('common--disabled', #{colors.get(neutral, 400)});
	@include rgbfy('common--error', $color-danger);
	@include rgbfy('common--secondary', #{colors.get(pink, 500)});
	@include rgbfy('common--success', #{colors.get(alert, success_600)});
	@include rgbfy('common--warning', #{colors.get(alert, progress_500)});
	@include rgbfy('common-tabs--container-bg', $color-primary-2);
	@include rgbfy('common-tabs--link', colors.get(purple, 500));
	@include rgbfy('common-tabs--link-active', $color-primary);
	@include rgbfy('common-tabs--link-active-secondary', colors.get(purple, 700));
	@include rgbfy('common-tabs--link-active-bg', $color-background-container);
	@include rgbfy('common-tabs--link-active-border', $color-primary-active);
	@include rgbfy('common-tabs--link-bg', $color-primary-1);
	@include rgbfy('common-tabs--link-border', $color-border);
	@include rgbfy('common-tabs--link-hover', $color-primary);
	@include rgbfy('common-tabs--link-spacer', #{colors.get(red, 600)});
	@include rgbfy('crosslogin--header', $color-primary);
	@include rgbfy('crosslogin--text', $color-text);
	@include rgbfy('crosslogin--text-strong', $color-text);
	@include rgbfy('company-reg--upload-result-bg', #{colors.get(neutral, 150)});
	@include rgbfy('company-reg--upload-result-shadow', transparent);
	@include rgbfy('content-background', #{colors.get(red, 500)});
	@include rgbfy('content-header-border', #{colors.get(red, 800)});
	@include rgbfy('contest--icon-play', #{colors.get(neutral, 100)});
	@include rgbfy('contest--shadow', #{colors.get(neutral, 900)});
	@include rgbfy('contest--tab-background', $color-primary-2);
	@include rgbfy('date-filter--bg', $color-border);
	@include rgbfy('date-filter--text', #{colors.get(neutral, 700)});
	@include rgbfy('display-name-overlay--text', #{colors.get(purple, 600)});
	@include rgbfy('display-name-overlay--text-strong', #{colors.get(purple, 700)});
	@include rgbfy('display-name-overlay--caption', #{colors.get(purple, 600)});
	@include rgbfy('download-guide--bg', #{colors.get(red, 500)});
	@include rgbfy('download-guide--close', #{colors.get(pink, 500)});
	@include rgbfy('download-guide--dot', #{colors.get(neutral, 400)});
	@include rgbfy('download-guide--header-bg', #{colors.get(red, 600)});
	@include rgbfy('download-guide--header-text', #{colors.get(pink, 600)});
	@include rgbfy('download-guide--text', #{colors.get(pink, 400)});
	@include rgbfy('earn-more--exclusive-model--title', #{colors.get(red, 700)});
	@include rgbfy('earn-more--exclusive-model--gradient-from', #{colors.get(red, 500)});
	@include rgbfy('earn-more--exclusive-model--gradient-to', #{colors.get(red, 600)});
	@include rgbfy('earn-more--jasmin-elite--progress-bar', #{colors.get(red, 800)});
	@include rgbfy('earn-more--jasmin-elite--section-bg', #{colors.get(red, 500)});
	@include rgbfy('earn-more--jasmin-elite--shadow', #660000);
	@include rgbfy('earn-more--king-of-the-room--section-bg', #{colors.get(red, 500)});
	@include rgbfy('earn-more--king-of-the-room--section-bg-gradient', #{colors.get(red, 700)});
	@include rgbfy('earn-more--king-of-the-room--title-gradient-from', #{colors.get(yellow, 500)});
	@include rgbfy('earn-more--king-of-the-room--title-gradient-to', #FD9402);
	@include rgbfy('earn-more--my-story--badge-bg', #{colors.get(red, 400)});
	@include rgbfy('earn-more--my-story--badge-border', #{colors.get(red, 700)});
	@include rgbfy('earn-more--my-story--more-info-bg', #{colors.get(red, 500)});
	@include rgbfy('earn-more--my-story--section-bg', #{colors.get(red, 700)});
	@include rgbfy('earn-more--my-story--section-bg-gradient', #{colors.get(red, 500)});
	@include rgbfy('earn-more--my-story--shadow', #660000);
	@include rgbfy('earn-more--private-show-discount--gradient-from', #{colors.get(red, 500)});
	@include rgbfy('earn-more--private-show-discount--gradient-to', #{colors.get(red, 600)});
	@include rgbfy('earn-more--section-bg', #{colors.get(red, 600)});
	@include rgbfy('earn-more--selection--badge-before-border', #{colors.get(red, 600)});
	@include rgbfy('earn-more--selection--badge-before-gr-from', #{colors.get(red, 300)});
	@include rgbfy('earn-more--selection--badge-before-gr-to', #{colors.get(red, 500)});
	@include rgbfy('earn-more--selection--badge-bg', #{colors.get(red, 600)});
	@include rgbfy('earn-more--selection--badge-border', #CA4646);
	@include rgbfy('earn-more--selection--badge-text', #CA4646);
	@include rgbfy('earn-more--selection--countdown-bg', #{colors.get(red, 800)});
	@include rgbfy('earn-more--selection--countdown-text', #{colors.get(pink, 400)});
	@include rgbfy('earn-more--selection--widget-completed-bg', #{colors.get(red, 600)});
	@include rgbfy('earn-more--selection--widget-completed-title-text', #CA4646);
	@include rgbfy('earn-more--selection--widget-li-bg', #{colors.get(red, 700)});
	@include rgbfy('earn-more--selection--widget-li-hover-bg', #660000);
	@include rgbfy('earn-more--snapshot--member-album-bg', #{colors.get(red, 600)});
	@include rgbfy('earn-more--snapshot--member-album-border', #{colors.get(red, 700)});
	@include rgbfy('earn-more--snapshot--member-album-icon', #CA4646);
	@include rgbfy('earn-more--snapshot--member-album-small-text', #{colors.get(pink, 500)});
	@include rgbfy('earn-more--snapshot--member-album-user', #{colors.get(pink, 600)});
	@include rgbfy('earn-more--status-header-bg', $color-background-widget);
	@include rgbfy('earn-more--text', #{colors.get(pink, 400)});
	@include rgbfy('earn-more--text-gradient-start', #{colors.get(yellow, 400)});
	@include rgbfy('earn-more--text-gradient-stop', #F5A04B);
	@include rgbfy('earn-more--twowayaudio--block-gradient-start', #{colors.get(red, 700)});
	@include rgbfy('earn-more--twowayaudio--block-gradient-stop', transparent);
	@include rgbfy('earn-more--video-voice-call--badge-bg', #{colors.get(red, 400)});
	@include rgbfy('earn-more--video-voice-call--badge-border', #{colors.get(red, 700)});
	@include rgbfy('earn-more--video-voice-call--section-bg-gradient', #{colors.get(red, 700)});
	@include rgbfy('email-confirmation--icon', colors.get(purple, 400));
	@include rgbfy('email-confirmation--text', $color-text-muted);
	@include rgbfy('email-confirmation--title', $color-primary);
	@include rgbfy('first-login-steps-bg', $color-primary-1);
	@include rgbfy('form--addon-bg', #{colors.get(neutral, 500)});
	@include rgbfy('form--addon-border', #{colors.get(neutral, 300)});
	@include rgbfy('form--addon-text', #{colors.get(neutral, 500)});
	@include rgbfy('form--alert-icon', #{colors.get(alert, progress_500)});
	@include rgbfy('form--alert-icon-rejected', $color-danger);
	@include rgbfy('form--dropdown--button-background', $color-text-muted);
	@include rgbfy('form--dropdown--button-background-darken', transparent);
	@include rgbfy('form--dropdown--button-caret', $color-text-inversed);
	@include rgbfy('form--dropdown--item-hover-background', $color-background);
	@include rgbfy('form--dropdown--item-text', $color-text-muted);
	@include rgbfy('form--dropdown--item-hover-text', $color-primary);
	@include rgbfy('form--highlight', #{colors.get(alert, progress_500)});
	@include rgbfy('form--info-icon', $color-text-muted);
	@include rgbfy('form--info-icon-hover', $color-primary);
    @include rgbfy('form--ok-icon', colors.get(alert, success_600));
	@include rgbfy('form--input-caret', $color-primary-active);
	@include rgbfy('form--input-background', $color-background-container);
	@include rgbfy('form--input-background-lighten-15', $color-background-container);
	@include rgbfy('form--input-background-in-mediabox', transparent);
	@include rgbfy('form--input-border', $color-border);
	@include rgbfy('form--input-focus-background', $color-background-container);
	@include rgbfy('form--input-focus-border', $color-primary);
	@include rgbfy('form--input-focus-text', $color-primary);
	@include rgbfy('form--input-loading', $color-primary-1);
	@include rgbfy('form--input-placeholder-text', $color-text-inversed-muted);
	@include rgbfy('form--input-placeholder-icon', $color-text-muted);
	@include rgbfy('form--input-placeholder-clear-search', $color-text-muted);
	@include rgbfy('form--input-state--error-border', $color-danger);
	@include rgbfy('form--input-state--error-text', $color-danger);
	@include rgbfy('form--input-text', $color-text-muted);
	@include rgbfy('form--input-shadow', transparent);
    @include rgbfy('form--input-eye-icon', $color-text-muted);
    @include rgbfy('form--input-eye-icon-hover', $color-text-muted);
	@include rgbfy('form--radio-background', transparent);
	@include rgbfy('form--radio-border', #{colors.get(purple, 300)});
	@include rgbfy('form--radio-check', $color-primary-active);
	@include rgbfy('form--required-asterix', #{colors.get(yellow, 400)});
	@include rgbfy('form--selectorbox-border', $color-border);
	@include rgbfy('form--selectorbox-border-hover', $color-primary-3);
	@include rgbfy('form--shadow', #{colors.get(neutral, 900)});
	@include rgbfy('form-text', $color-primary);
	@include rgbfy('form--toggle--handler-background', #{colors.get(red, 500)});
	@include rgbfy('form--toggle--on-background', #{colors.get(alert, success_600)});
	@include rgbfy('form--checkbox-border', $color-border);
	@include rgbfy('form--checkbox-border-disabled', rgba($color-border,.4));
	@include rgbfy('form--checkbox-bg', $color-background-container);
	@include rgbfy('form--checkbox-check', $color-primary-active);
	@include rgbfy('form-date--apply-bg', #{colors.get(decorative, pink_400)});
	@include rgbfy('form-date--apply-text', #{colors.get(neutral, 100)});
	@include rgbfy('form-date--dropdown-icon', #{colors.get(neutral, 500)});
	@include rgbfy('form-date--range-selection', #{colors.get(neutral, 500)});
	@include rgbfy('form-date--range-selection-text', #{colors.get(neutral, 100)});
	@include rgbfy('gallery--box-bg', #{colors.get(neutral, 900)});
	@include rgbfy('gallery--crop-tool', $turquise);
	@include rgbfy('gallery--image-overlay-text', #{colors.get(neutral, 500)});
	@include rgbfy('gallery--loading-shadow', $color-background-container);
	@include rgbfy('gallery--profile-pic-bg', #{colors.get(neutral, 700)});
	@include rgbfy('gallery--profile-pic-border', $color-border);
	@include rgbfy('gallery--profile-selector-bg', $color-background-container);
	@include rgbfy('gallery--selected', $color-primary-active);
	@include rgbfy('gallery--settings-overlay-bg', #{colors.get(neutral, 900, 0.8)});
	@include rgbfy('gallery--settings-overlay-border', #{colors.get(neutral, 100, 0.8)});
	@include rgbfy('gallery--icon-photo-placeholder', colors.get(purple, 600));
	@include rgbfy('gallery--icon-check', colors.get(alert, success_600));
	@include rgbfy('gallery--icon-warning', colors.get(yellow, 500));
	@include rgbfy('gallery--icon-info', colors.get(purple, 500));
	@include rgbfy('gallery--icon-info-hover', colors.get(neutral, 100));
	@include rgbfy('gallery--shadow', #{colors.get(neutral, 900)});
	@include rgbfy('global-progress-indicator--bg', transparent);
	@include rgbfy('global-progress-indicator--inner-bg', transparent);
	@include rgbfy('guide--bottom-bg', #{colors.get(neutral, 100)});
	@include rgbfy('guide--bottom-border', #{colors.get(neutral, 300)});
	@include rgbfy('guide--separator-border-left', #{colors.get(neutral, 300)});
	@include rgbfy('guide--separator-border-right', #{colors.get(neutral, 300)});
	@include rgbfy('guide--shadow', #{colors.get(neutral, 900)});
	@include rgbfy('hamburger', #{colors.get(neutral, 500)});
	@include rgbfy('header--border', #{colors.get(red, 500)});
	@include rgbfy('header--gr-from', #{colors.get(red, 400)});
	@include rgbfy('header--gr-from-darken', #{colors.get(red, 700)});
	@include rgbfy('header--gr-to', #660000);
	@include rgbfy('h2-color', $color-primary);
	@include rgbfy('hr--light-background', $color-background-container);
	@include rgbfy('hr-background', $color-border);
	@include rgbfy('hr-background-lighten-25', $color-background-container);
	@include rgbfy('huge-traffic-overlay--bold', #{colors.get(neutral, 100)});
	@include rgbfy('huge-traffic-overlay--text', #{colors.get(pink, 400)});
	@include rgbfy('huge-traffic-overlay--title', #{colors.get(yellow, 500)});
	@include rgbfy('icon--alert--yellow', colors.get(alert, progress_500));
	@include rgbfy('icon--help--hover', #200000);
	@include rgbfy('icon--help--shadow', #{colors.get(red, 400)});
	@include rgbfy('icon--help--text', #{colors.get(red, 800)});
	@include rgbfy('icon--info--pink', #{colors.get(pink, 600)});
	@include rgbfy('icon--info--yellow', $color-warning);
	@include rgbfy('icon--ok--green', colors.get(alert, success_600));
	@include rgbfy('icon--shadow', $color-warning);
	@include rgbfy('icon--tick--yellow', $color-secondary);
	@include rgbfy('icon--tick--turquise', $turquise);
	@include rgbfy('icon-in-circle--icon-gradient-end', #F5A04B);
	@include rgbfy('icon-in-circle--icon-gradient-start', #{colors.get(yellow, 400)});
	@include rgbfy('icon-in-circle--main', $color-warning);
	@include rgbfy('id-uploader--border', $color-primary-2);
	@include rgbfy('id-uploader--dropzone-background', $color-background-container);
	@include rgbfy('id-uploader--dropzone-background-lighten', $color-background-container);
	@include rgbfy('id-uploader--icon', #{colors.get(neutral, 100)});
	@include rgbfy('id-uploader--shadow', #{colors.get(neutral, 900)});
	@include rgbfy('members--empty-icon', $color-background);
	@include rgbfy('members--empty-text', $color-text);
	@include rgbfy('members--empty-profile-bg', $color-background);
	@include rgbfy('members--empty-profile-icon', $color-background-container);
	@include rgbfy('members--favorite', $color-primary-3);
	@include rgbfy('members--favorite-active', $color-primary-5);
	@include rgbfy('members--disabled-member-name', $color-primary-2);
	@include rgbfy('members--indicator--offline', #{colors.get(red, 800)});
	@include rgbfy('members--indicator--online', #12ff00);
	@include rgbfy('members--indicator--shadow-1', #{colors.get(red, 400)});
	@include rgbfy('members--indicator--shadow-2', #{colors.get(red, 800)});
	@include rgbfy('members--link', $color-primary-3);
	@include rgbfy('members--link-hover', $color-primary-6);
	@include rgbfy('members--avatar', #{colors.get(neutral, 100)});
	@include rgbfy('members--avatar-bg', $color-primary-3);
	@include rgbfy('members--note-border', $color-border);
	@include rgbfy('members--note-delete', $color-link);
	@include rgbfy('members--note-delete-hover', $color-link-hover);
	@include rgbfy('members--profile-picture-texts', #{colors.get(neutral, 100)});
	@include rgbfy('members--shadow', $color-background);
	@include rgbfy('members--tab-bg', $color-primary-1);
	@include rgbfy('members--tab-container-bg', $color-background);
	@include rgbfy('members--list-item-subtitle', $color-primary);
	@include rgbfy('members--no-results-icon', $color-background);
	@include rgbfy('members--icon', #{colors.get(purple, 500)});
	@include rgbfy('members--icon-active', #{colors.get(purple, 700)});
	@include rgbfy('menu--account-exclusive', $color-text-inversed);
	@include rgbfy('menu--account-name', $color-text-inversed);
	@include rgbfy('menu--account-name-link', $color-secondary);
	@include rgbfy('menu--studio-account-type', $color-text-inversed-muted);
	@include rgbfy('menu--account-type', $color-text-inversed-muted);
	@include rgbfy('menu--account-type-pending', $color-warning);
	@include rgbfy('menu--account-type-missing-data', $color-warning);
	@include rgbfy('menu--account-type-rejected', $color-danger);
	@include rgbfy('menu--account-type-active', #{colors.get(alert, success_600)});
	@include rgbfy('menu--account-type-inactive', $color-inactive);
	@include rgbfy('menu--account-type-suspended', $color-inactive);
	@include rgbfy('menu--account-type-closed', $color-inactive);
	@include rgbfy('menu--bg', $color-primary-6);
	@include rgbfy('menu--active-bg', $color-primary-active);
	@include rgbfy('menu--active-shadow', transparent);
	@include rgbfy('menu--active-submenu-parent', $color-primary);
	@include rgbfy('menu--active-text', $color-text-inversed);
	@include rgbfy('menu--box-background', $color-primary-7);
	@include rgbfy('menu--double-image-border', $color-background);
	@include rgbfy('menu--hover-bg', $color-primary-8);
	@include rgbfy('menu--hover-bg-rgba', $color-primary-8);
	@include rgbfy('menu--mobile-background', $color-primary);
	@include rgbfy('menu--mobile-cover-background', $color-primary);
	@include rgbfy('menu--new-bg', $color-caution);
	@include rgbfy('menu--new-text', $color-text);
	@include rgbfy('menu--notification--active--border', $color-primary-active);
	@include rgbfy('menu--notification--counter', $color-text-inversed-muted);
	@include rgbfy('menu--notification--counter--active', $color-text-inversed);
	@include rgbfy('menu--notification--border', $color-primary);
	@include rgbfy('menu--notification--hover--border', $color-primary-8);
	@include rgbfy('menu--notification--hover--sub', $color-primary);
	@include rgbfy('menu--profile-picture-bg', $color-primary-8);
	@include rgbfy('menu--profile-picture-mobile-bg', $color-primary);
	@include rgbfy('menu--profile-picture-default-icon', #{colors.get(purple, 400)});
	@include rgbfy('menu--profile-picture-missing-icon', #{colors.get(purple, 400, 0.15)});
	@include rgbfy('menu--profile-picture-overlay', $color-primary);
	@include rgbfy('menu--profile-picture-icon', $color-secondary-1);
	@include rgbfy('menu--profile-picture-text-hover', #{colors.get(neutral, 100)});
	@include rgbfy('menu--profile-picture-upload', $color-secondary-1);
	@include rgbfy('menu--selected-bg', transparent);
	@include rgbfy('menu--separator', #{colors.get(neutral, 100, 0.1)});
	@include rgbfy('menu--separator-active', #{colors.get(neutral, 100, 0.1)});
	@include rgbfy('menu--studio-image-background', $color-primary-8);
	@include rgbfy('menu--sub-text', $color-text-inversed-muted);
	@include rgbfy('menu--text', $color-text-inversed-muted);
	@include rgbfy('messages--attachment-icon', #CA4646);
	@include rgbfy('messages--attachment-uploader-bg', #{colors.get(neutral, 150)});
	@include rgbfy('messages--empty-icon', colors.get(purple, 400));
    @include rgbfy('messages--empty-bg', colors.get(purple, 100));
    @include rgbfy('messages--empty-description', colors.get(purple, 500));
	@include rgbfy('messages--list--active-bg', #{colors.get(neutral, 400)});
	@include rgbfy('messages--support--attachment-icon', $color-primary-5);
	@include rgbfy('messenger--tab--a', $color-primary-4);
	@include rgbfy('messenger--tab-active', $color-primary-6);
	@include rgbfy('messenger--tab--active-border', #{colors.get(decorative, violet_500)});
	@include rgbfy('messenger--tab--border', #{colors.get(purple, 300)});
	@include rgbfy('messenger--tab--active-text', #{colors.get(decorative, violet_500)});
	@include rgbfy('messenger--tab--inactive-text', #{colors.get(purple, 500)});
	@include rgbfy('migration--choose-box-bg', #{colors.get(neutral, 400)});
	@include rgbfy('migration--choose-box-mask', #{colors.get(red, 500)});
	@include rgbfy('migration--choose-box-mask-strong', #{colors.get(red, 800)});
	@include rgbfy('migration--finish-form-bg', #{colors.get(red, 600)});
	@include rgbfy('mixin--common-text-gr--from', #{colors.get(yellow, 500)});
	@include rgbfy('mixin--common-text-gr--to', #EDA300);
	@include rgbfy('mobile-crop-overlay--bold', #{colors.get(neutral, 100)});
	@include rgbfy('mobile-crop-overlay--text', #{colors.get(pink, 500)});
	@include rgbfy('mobile-crop-overlay-needed--text', #{colors.get(neutral, 500)});
	@include rgbfy('models--bad-rating-border', #{colors.get(alert, danger_500)});
	@include rgbfy('models--bad-rating-text', #{colors.get(alert, danger_500)});
	@include rgbfy('models--choose-existing-name', $turquise);
	@include rgbfy('models--choose-existing-alert-text', #{colors.get(neutral, 500)});
	@include rgbfy('models--choose-existing-alert-icon', #{colors.get(red, 300)});
	@include rgbfy('models--choose-existing-selected-border', $turquise);
	@include rgbfy('models--current-level-sign-inner-bg', $gradient-lulu);
	@include rgbfy('models--current-level-sign-inner-text', #{colors.get(neutral, 100)});
	@include rgbfy('models--current-level-sign-inner-shadow', #{colors.get(neutral, 100, 0.2)});
	@include rgbfy('models--current-level-sign-inner-text-shadow', transparent);
	@include rgbfy('models--current-level-sign-outer-bg', $gradient-lulu);
	@include rgbfy('models--empty-text-header', #{colors.get(decorative, pink_400)});
	@include rgbfy('models--empty-figure', #{colors.get(neutral, 500)});
	@include rgbfy('models--filter-text', $color-primary-4);
	@include rgbfy('models--icon', $color-text-muted);
	@include rgbfy('models--icon-hover', $color-link-hover);
	@include rgbfy('models--icon-active', $color-primary-active);
    @include rgbfy('models--profile-add-icon', colors.get(purple, 600));
	@include rgbfy('models--info-box-border', $color-border);
	@include rgbfy('models--level-sign-outer-shadow', #{colors.get(neutral, 900, 0.1)});
	@include rgbfy('models--manage-active-status', #{colors.get(alert, success_600)});
	@include rgbfy('models--manage-image-online-border', #00B259);
	@include rgbfy('models--next-level-bar-bg', #{colors.get(neutral, 300)});
	@include rgbfy('models--next-level-bar-line-bg', $gradient-lulu80);
	@include rgbfy('models--next-level-bar-line-end-bg', $dance);
	@include rgbfy('models--next-level-bar-line-start-bg', $lulu);
	@include rgbfy('models--next-level-bar-line-text', #{colors.get(neutral, 100)});
	@include rgbfy('models--next-level-bar-shadow', transparent);
	@include rgbfy('models--next-level-border', transparent);
	@include rgbfy('models--next-level-sign-inner-shadow', #{colors.get(neutral, 300)});
	@include rgbfy('models--next-level-sign-inner-text-shadow', transparent);
	@include rgbfy('models--next-level-sign-outer-bg', #{colors.get(neutral, 400)});
	@include rgbfy('models--no-rating-text', $color-text-muted);
    @include rgbfy('models--choose-type-text', #{colors.get(purple, 500)});
	@include rgbfy('models--total-payouts', #{colors.get(decorative, pink_400)});
	@include rgbfy('models--status-info-text', #{colors.get(neutral, 700)});
	@include rgbfy('models--status-info-bg', #{colors.get(neutral, 400)});
	@include rgbfy('models--summarize-text', $plum);
	@include rgbfy('models--quality-info-bg', $color-background);
	@include rgbfy('my-story--background', #{colors.get(neutral, 900)});
	@include rgbfy('my-story--dialog-background', #{colors.get(neutral, 500)});
	@include rgbfy('my-story--dialog-close', #{colors.get(neutral, 400)});
	@include rgbfy('my-story--expire', #{colors.get(pink, 400)});
	@include rgbfy('my-story--main-close', #{colors.get(neutral, 100)});
	@include rgbfy('my-story--menu', #{colors.get(neutral, 100)});
	@include rgbfy('my-story--play', #{colors.get(neutral, 100)});
	@include rgbfy('my-story--shade', #{colors.get(neutral, 900)});
	@include rgbfy('my-story--stripe-free', #{colors.get(neutral, 100)});
	@include rgbfy('my-story--stripe-free-darken', #{colors.get(neutral, 600)});
	@include rgbfy('my-story--stripe-premium', #{colors.get(yellow, 500)});
	@include rgbfy('my-story--stripe-premium-darken', #dfb920);
	@include rgbfy('notification--background', #{colors.get(neutral, 900, 0.8)});
	@include rgbfy('notification--dark-background', #{colors.get(neutral, 900, 0.95)});
	@include rgbfy('notification--icon-silde-text', #{colors.get(red, 800)});
	@include rgbfy('notification--light-icon', #{colors.get(red, 300)});
	@include rgbfy('notification--text', #{colors.get(neutral, 100)});
	@include rgbfy('old-box--success-icon', #{colors.get(neutral, 100)});
	@include rgbfy('old-box--alert-bg', #{colors.get(neutral, 850)});
	@include rgbfy('old-box--alert-highlite-text', #{colors.get(neutral, 400)});
	@include rgbfy('old-box--alert-highlite-text-bg', #{colors.get(neutral, 900)});
	@include rgbfy('old-box--alert-icon', #{colors.get(neutral, 800)});
	@include rgbfy('old-box--alert-text', #{colors.get(neutral, 500)});
	@include rgbfy('old-box--calendar--bg', $color-background-widget);
	@include rgbfy('old-box--calendar--border-active', $color-border);
	@include rgbfy('old-box--calendar--border-passive', $color-border);
	@include rgbfy('old-box--calendar--icon', $color-text-muted);
	@include rgbfy('old-box--calendar--text-passive', $color-text-inversed-muted);
	@include rgbfy('old-box--error-bg', $rosie);
	@include rgbfy('old-box--error-icon', #{colors.get(neutral, 800)});
	@include rgbfy('old-box--error-text', #{colors.get(neutral, 500)});
	@include rgbfy('old-box--error-description', #{colors.get(neutral, 300)});
	@include rgbfy('old-box--info-bg-dark', #{colors.get(red, 800)});
	@include rgbfy('old-box--sticky-wrapper-shadow', #{colors.get(neutral, 850)});
	@include rgbfy('overlay-background', $color-background-container);
	@include rgbfy('overlay--close-button', $color-text-muted);
    @include rgbfy('overlay--close-button-hover', $color-text-muted);
	@include rgbfy('overlay--media-close-button', colors.get(neutral, 100));
	@include rgbfy('overlay-container-background', $color-primary-9);
	@include rgbfy('overlay-container-background-mobile', $color-background-container);
	@include rgbfy('overlay--text-shadow', #{colors.get(neutral, 900)});
	@include rgbfy('overlay--text-base', $color-text);
	@include rgbfy('overlay--header-h3', $color-primary);
	@include rgbfy('overlay--header-h3-white', #{colors.get(neutral, 100)});
	@include rgbfy('overlay--arrow-hover', #{colors.get(neutral, 100)});
	@include rgbfy('overlay--with-header-close-btn', $color-text-muted);
	@include rgbfy('overlay--without-header-close-btn', $color-text-muted);
	@include rgbfy('overlay--with-header-close-btn-icon-hover', $color-primary);
	@include rgbfy('overlay--with-header-close-btn-hover', $color-primary);
	@include rgbfy('overlay--without-header-close-btn-hover', $color-primary);
	@include rgbfy('page-background', $color-background-container);
	@include rgbfy('page-shadow', #{colors.get(neutral, 900, 0.1)});
	@include rgbfy('payout-income--period-bg', #{colors.get(neutral, 150)});
	@include rgbfy('payout-info-text', $color-text-inversed-muted);
	@include rgbfy('payout-icon-info-hover', colors.get(purple, 600));
	@include rgbfy('payout-icon-check', colors.get(alert, success_600));
	@include rgbfy('payout-options--box-bg', $color-background-widget);
	@include rgbfy('payout-options--box-border', #{colors.get(neutral, 100)});
	@include rgbfy('payout-options--box-text', $color-text-muted);
	@include rgbfy('payout-options--box-title', $color-primary);
	@include rgbfy('payout-options--info-icon', $color-text-muted);
	@include rgbfy('payout-type--box-arrow', $color-label-muted);
	@include rgbfy('payout-type--box-bg', $color-background-container);
	@include rgbfy('payout-type--box-border', $color-border);
	@include rgbfy('payout-type--border', $color-border);
	@include rgbfy('payout-type--header-bg', $color-background);
	@include rgbfy('payout-type--header-text', $color-primary);
	@include rgbfy('payout-info-header', #555);
	@include rgbfy('payout-info-detail-title', #{colors.get(neutral, 500)});
	@include rgbfy('payout-info-detail-value', #{colors.get(decorative, pink_400)});
	@include rgbfy('payout-info-info', #{colors.get(neutral, 500)});
	@include rgbfy('payout-overview--strong', colors.get(purple, 700));
	@include rgbfy('payout-overview--link', #{colors.get(blue, 500)});
	@include rgbfy('payout-overview--link-hover', #{colors.get(purple, 700)});
	@include rgbfy('payout-overview-description', colors.get(purple, 500));
	@include rgbfy('photo-contest--thumbnail-empty-back', #{colors.get(red, 600)});
	@include rgbfy('policy--side-note', #{colors.get(neutral, 300)});
	@include rgbfy('pornstar-info--earning', #{colors.get(yellow, 500)});
	@include rgbfy('pornstar-info--nth', #{colors.get(red, 700)});
	@include rgbfy('primary', #{colors.get(neutral, 100)});
	@include rgbfy('primary-darken-10', #{colors.get(red, 700)});
	@include rgbfy('privacy--box--header-bg', #{colors.get(neutral, 500)});
	@include rgbfy('privacy--box--header-text', #{colors.get(neutral, 100)});
	@include rgbfy('privacy-rights--attention-bg', $color-primary-1);
	@include rgbfy('privacy-rights--attention-text', $color-primary);
	@include rgbfy('privacy-rights--warning', $color-danger);
	@include rgbfy('privacy-rights--bold', $color-text);
	@include rgbfy('privacy-rights--text', $color-text);
	@include rgbfy('privacy-rights--icon', #{colors.get(red, 300)});
	@include rgbfy('progress-bar--bg', $color-background);
	@include rgbfy('progress-bar--line', $color-primary-4);
	@include rgbfy('progress-bar--stripe', $color-text-muted);
	@include rgbfy('promo-tools--box-bg', #{colors.get(red, 700)});
	@include rgbfy('promo-tools--box-border', #{colors.get(red, 700)});
	@include rgbfy('promo-tools--box-hover-bg', #{colors.get(red, 800)});
	@include rgbfy('promo-tools--date', $color-primary-3);
	@include rgbfy('promo-tools--icon-active', colors.get(neutral, 100));
	@include rgbfy('promo-tools--info', colors.get(purple, 900));
	@include rgbfy('promo-tools--info-border', #{colors.get(red, 600)});
	@include rgbfy('promo-tools--info-bullet', #CA4646);
	@include rgbfy('promo-tools--info-icon', $color-text-inversed-muted);
	@include rgbfy('promo-tools--info-list-bg', $color-primary-1);
	@include rgbfy('promo-tools--info-list-text', $color-text);
	@include rgbfy('promo-tools--layer-bg', #{colors.get(red, 800)});
	@include rgbfy('promo-tools--play', #{colors.get(neutral, 100)});
	@include rgbfy('promo-tools--preview-bg', $color-primary);
	@include rgbfy('promo-tools--preview-text', #{colors.get(neutral, 100)});
	@include rgbfy('promo-tools--title', #{colors.get(pink, 600)});
	@include rgbfy('promo-tools--title-active', #{colors.get(neutral, 100)});
	@include rgbfy('promo-video--help-heading-bg', #{colors.get(red, 500)});
	@include rgbfy('promo-video--help-section-gradient-from', #{colors.get(red, 600)});
	@include rgbfy('promo-video--help-section-gradient-to', #{colors.get(red, 700)});
	@include rgbfy('promo-video--list-container-bg', #{colors.get(red, 600)});
	@include rgbfy('promo-video--list-item', #{colors.get(pink, 400)});
	@include rgbfy('promo-video--mark-as-promo-border', #{colors.get(red, 600)});
	@include rgbfy('promo-video--mark-as-promo-footer', #{colors.get(pink, 500)});
	@include rgbfy('promo-video--more-info-link', #{colors.get(yellow, 500)});
	@include rgbfy('promo-video--step-description', #{colors.get(pink, 400)});
	@include rgbfy('promo-video--step-number', #{colors.get(yellow, 500)});
	@include rgbfy('promotion-banner--bg', #{colors.get(neutral, 400)});
	@include rgbfy('promotion-banner--qr', #{colors.get(neutral, 100)});
	@include rgbfy('promotion-block-text', transparent);
	@include rgbfy('promotion-tools--info-page--step-count', #{colors.get(yellow, 500)});
	@include rgbfy('promotion-tools--info-page--video-shadow', #{colors.get(neutral, 900, 0.9)});
	@include rgbfy('protip--bg', #{colors.get(neutral, 900)});
	@include rgbfy('protip--text', $color-text-inversed);
	@include rgbfy('protip--text-muted', #{colors.get(neutral, 400)});
	@include rgbfy('protip--link', $color-secondary-1);
	@include rgbfy('protip--link-hover', $color-link-inversed);
	@include rgbfy('referral--form-bg', #{colors.get(neutral, 400)});
	@include rgbfy('referral--link', #{colors.get(decorative, pink_400)});
	@include rgbfy('ribbon--bg-end', #{colors.get(alert, progress_500)});
	@include rgbfy('ribbon--bg-start', #{colors.get(yellow, 500)});
	@include rgbfy('ribbon--icon', #{colors.get(red, 800)});
	@include rgbfy('ribbon--shadow-end', #{colors.get(yellow, 500)});
	@include rgbfy('ribbon--shadow-end-darken', #d6ab00);
	@include rgbfy('ribbon--shadow-inner', #{colors.get(neutral, 100)});
	@include rgbfy('ribbon--shadow-start', #{colors.get(neutral, 850)});
	@include rgbfy('ribbon--text', #{colors.get(neutral, 800)});
	@include rgbfy('ribbon--text-new', #{colors.get(red, 800)});
	@include rgbfy('scrollable--default--bar-bg', rgba($color-label-muted, 0.4));
	@include rgbfy('scrollable--default--track-bg', transparent);
	@include rgbfy('scrollable--light--bar-bg', #{colors.get(neutral, 100)});
	@include rgbfy('scrollable--light--bg', #{colors.get(neutral, 800)});
	@include rgbfy('scrollable--light--border', #{colors.get(neutral, 800)});
	@include rgbfy('scrollable--light--track-bg', $color-label-muted);
	@include rgbfy('scrollable--light--track-lines', $color-primary);
	@include rgbfy('scrollable--secondary--bar-bg', #{colors.get(neutral, 500)});
	@include rgbfy('scrollable--item-list', $color-text-muted);
	@include rgbfy('scrollable--item-list-hover', $color-primary);
	@include rgbfy('scrollable--item-list-hover-bg', $color-background);
	@include rgbfy('scrollable--item-list-selected', $color-primary-active);
	@include rgbfy('scrollpane--horizontal--bg', #{colors.get(pink, 600)});
	@include rgbfy('scrollpane--horizontal--border', #{colors.get(pink, 600)});
	@include rgbfy('scrollpane--scrollbar-bg', #{colors.get(neutral, 900, 0.3)});
	@include rgbfy('sidebar--background', #{colors.get(red, 800)});
	@include rgbfy('selection--background', grey);
	@include rgbfy('selection--text', #{colors.get(neutral, 100)});
	@include rgbfy('snapshot--action-icon', colors.get(neutral, 100));
	@include rgbfy('snapshot--camera-bg', $color-background-container);
	@include rgbfy('snapshot--counter-text', $color-text-inversed-muted);
	@include rgbfy('snapshot--counter-text-active', $color-link);
	@include rgbfy('snapshot--ready-bg', #{colors.get(red, 600)});
	@include rgbfy('snapshot--warning-bg', #{colors.get(neutral, 900)});
	@include rgbfy('statistics-income--back-bg', $color-background-widget);
	@include rgbfy('statistics-income--back-text', $color-text-muted);
	@include rgbfy('statistics-income--back-text-hover', $color-primary);
	@include rgbfy('statistics-income--border', $color-border);
	@include rgbfy('statistics-income--border-details', $color-border);
	@include rgbfy('statistics-income--current-earnings-bg', $color-background-widget);
	@include rgbfy('statistics-income--current-earnings-coin', $color-text-muted);
	@include rgbfy('statistics-income--current-earnings-text', $color-text-muted);
	@include rgbfy('statistics-income--current-earnings-value', $color-primary);
	@include rgbfy('statistics-income--info-icon', $color-text-muted);
	@include rgbfy('statistics-income--level-achieved', $color-primary-active);
	@include rgbfy('statistics-income--level-share', $color-primary-active);
	@include rgbfy('statistics-income--list-arrow', $color-text-muted);
	@include rgbfy('statistics-income--list-arrow-hover', $color-primary);
	@include rgbfy('statistics-income--list-bg', $color-background-widget);
	@include rgbfy('statistics-income--list-container-bg', $color-background);
	@include rgbfy('statistics-income--list-item-bg', $color-background-widget);
	@include rgbfy('statistics-income--list-data', $color-text-muted);
	@include rgbfy('statistics-income--list-date', $color-primary);
	@include rgbfy('statistics-income--list-details', $color-primary);
	@include rgbfy('statistics-income--list-header', $color-primary);
	@include rgbfy('statistics-income--list-icon', $color-primary-active);
	@include rgbfy('statistics-income--list-payout-plus', #{colors.get(alert, success_600)});
	@include rgbfy('statistics-income--list-payout-minus', $color-danger);
	@include rgbfy('statistics-income--list-text', $color-primary);
	@include rgbfy('statistics-income--list-value', $color-primary);
	@include rgbfy('statistics-income--list-null-value', $color-inactive);
	@include rgbfy('statistics-income--no-data', $color-text-muted);
	@include rgbfy('statistics-income--tooltip-background', #{colors.get(neutral, 900)});
	@include rgbfy('statistics-income--tooltip-font', $color-text-inversed);
	@include rgbfy('statistics-income--share-bg', $gradient-primary);
	@include rgbfy('statistics-income--share-text', $color-text-inversed);
	@include rgbfy('statistics-income--slider-dot', $color-primary-active);
	@include rgbfy('statistics-income--slider-dot-border', #{colors.get(neutral, 100)});
	@include rgbfy('statistics-income--slider-dot-hover', $color-primary-51);
	@include rgbfy('statistics-income--slider-line-active', $color-primary-active);
	@include rgbfy('stepcrumb--background', transparent);
	@include rgbfy('stepcrumb--dash', $color-border);
	@include rgbfy('stepcrumb--background-overlay', $emperor);
	@include rgbfy('stepcrumb--background-overlay-mobile', $color-background-container);
	@include rgbfy('stepcrumb--active-underline', $color-primary);
	@include rgbfy('stepcrumb--modelreg--active', $color-primary);
	@include rgbfy('stepcrumb--modelreg--past', $color-primary-active);
	@include rgbfy('stickyscroll--background', #{colors.get(red, 500)});
	@include rgbfy('support-chat--background', $color-background-widget);
	@include rgbfy('support-chat--background-focus', #{colors.get(red, 700)});
	@include rgbfy('support-chat--button-bg-from', $color-caution);
	@include rgbfy('support-chat--button-bg-to', $color-caution);
	@include rgbfy('support-chat--button-bg-hover', $color-secondary-2);
	@include rgbfy('support-chat--button-text', #{colors.get(neutral, 900)});
	@include rgbfy('support-chat--input-bg', $color-background-container);
	@include rgbfy('support-chat--input-border', $color-background-container);
	@include rgbfy('support-chat--input-placeholder', $color-primary);
	@include rgbfy('support-chat--input-text', $color-primary);
	@include rgbfy('support-chat--rating-bg', #{colors.get(neutral, 900)});
	@include rgbfy('support-chat--rating-text', #{colors.get(neutral, 100)});
	@include rgbfy('support-chat--support', $color-primary);
	@include rgbfy('support-chat--system', $color-text-muted);
	@include rgbfy('support-chat--topic', colors.get(purple, 500));
	@include rgbfy('support-chat--topic-hover', colors.get(purple, 700));
	@include rgbfy('support-chat--topic-bg', colors.get(purple, 100));
	@include rgbfy('support-chat--topic-border', colors.get(purple, 100));
	@include rgbfy('support-chat--user', $color-primary-active);
	@include rgbfy('support-chat--welcome', $color-text-muted);
	@include rgbfy('support-chat--zoom-bg', rgba($color-label-muted, 0.9));
	@include rgbfy('support-chat--zoom-bg-hover', $color-label-muted);
	@include rgbfy('support-chat--zoom-text', $color-text-inversed);
	@include rgbfy('switch-box--bg', #{colors.get(neutral, 150)});
	@include rgbfy('switch-box--border', $color-border);
	@include rgbfy('switch-box--hover-bg', #{colors.get(neutral, 300)});
	@include rgbfy('switch-box--hover-text', $color-link-hover);
	@include rgbfy('switch-box--shadow', #{colors.get(neutral, 100)});
	@include rgbfy('table--dark-bg', $color-background-widget);
	@include rgbfy('table--footer-bg', $color-background);
	@include rgbfy('table--header-bg', $color-label-muted);
	@include rgbfy('table--header-text', $color-text-inversed);
	@include rgbfy('table--highlight-bg', $color-background);
	@include rgbfy('table--regular-bg', $color-background-widget);
	@include rgbfy('table--secondary-bg', #{colors.get(red, 400)});
	@include rgbfy('table--small-text', $turquise);
	@include rgbfy('table--text', #{colors.get(pink, 600)});
	@include rgbfy('taglist--bg', #{colors.get(neutral, 300)});
	@include rgbfy('taglist--text', #{colors.get(neutral, 700)});
	@include rgbfy('text--approved', #{colors.get(alert, success_600)});
	@include rgbfy('text--base', $color-text);
	@include rgbfy('text--breadcrumb', $color-primary-3);
	@include rgbfy('text--breadcrumb-last', $color-primary);
	@include rgbfy('text--breadcrumb-last-item', $color-primary);
	@include rgbfy('text--emperor', $color-primary);
	@include rgbfy('text--link', $color-link);
	@include rgbfy('text--link-new', colors.get(blue, 500));
	@include rgbfy('text--link-darken-5', #e6b800);
	@include rgbfy('text--link-darker', #EDA300);
	@include rgbfy('text--link-hover', $color-link-hover);
	@include rgbfy('text--link-new-hover', colors.get(purple, 700));
	@include rgbfy('text--paragraph', $color-text);
	@include rgbfy('text--passive', $color-text-muted);
	@include rgbfy('text--rejected', #{colors.get(alert, danger_500)});
	@include rgbfy('text--secondary', $color-primary);
	@include rgbfy('text--secondary-darker', $color-text-muted);
	@include rgbfy('text--tertiary', $color-text-muted);
	@include rgbfy('text--waiting', $color-inactive);
	@include rgbfy('toggle-on-off--bg', #{colors.get(purple, 800, 0.08)});
	@include rgbfy('toggle-on-off--knob-off-gr-end', $color-text-muted);
	@include rgbfy('toggle-on-off--knob-off-gr-start', $color-text-muted);
	@include rgbfy('toggle-on-off--knob-on-gr-end', #{colors.get(alert, success_600)});
	@include rgbfy('toggle-on-off--knob-on-gr-start', #{colors.get(alert, success_600)});
	@include rgbfy('toggle-on-off--off-text', $color-text-muted);
	@include rgbfy('toggle-on-off--on-text', $color-label);
	@include rgbfy('toggle-on-off--shadow', transparent);
	@include rgbfy('toggle-on-off--switch-bg', #{colors.get(neutral, 900)});
	@include rgbfy('tooltip--bg', #{colors.get(neutral, 900, 0.9)});
	@include rgbfy('tooltip--text', #{colors.get(neutral, 300)});
	@include rgbfy('tooltip--close', $color-primary-2);
	@include rgbfy('tooltip--close-hover', $color-primary-3);
	@include rgbfy('tutorial--bg--end', #{colors.get(red, 600)});
	@include rgbfy('tutorial--bg--start', #{colors.get(red, 700)});
	@include rgbfy('tutorial--label', #{colors.get(yellow, 500)});
	@include rgbfy('tutorial--note', #{colors.get(pink, 500)});
	@include rgbfy('tutorial--text', #{colors.get(pink, 400)});
	@include rgbfy('uploader5--progress-shadow', #CA4646);
	@include rgbfy('uploader5--progress-text', $color-label-muted);
	@include rgbfy('uploader5--underlay', #{colors.get(neutral, 900)});
	@include rgbfy('vibratoy--border', #{colors.get(red, 600)});
	@include rgbfy('video-player--controls-bg', $color-background);
	@include rgbfy('video-player--failure-bg', #{colors.get(neutral, 800)});
	@include rgbfy('video-player--failure-text', #{colors.get(neutral, 500)});
	@include rgbfy('video-player--fullscreen-bg', #{colors.get(neutral, 900)});
	@include rgbfy('video-player--pager', #{colors.get(neutral, 100)});
	@include rgbfy('video-player--slider-gr-from', #{colors.get(yellow, 500)});
	@include rgbfy('video-player--slider-gr-to', #{colors.get(yellow, 500)});
	@include rgbfy('video-player--slider-shadow-from', #{colors.get(neutral, 100)});
	@include rgbfy('video-player--slider-shadow-to', #{colors.get(neutral, 900)});
	@include rgbfy('video-player--controls-play', #{colors.get(neutral, 300)});
	@include rgbfy('widget--daily-tip-note-input-text', #{colors.get(neutral, 500)});
	@include rgbfy('widget--deduction-bg', #{colors.get(red, 800)});
	@include rgbfy('widget--main-bg', #{colors.get(neutral, 400)});
	@include rgbfy('widget--news-header', #{colors.get(decorative, pink_400)});
	@include rgbfy('widget--news-text', #{colors.get(neutral, 500)});
	@include rgbfy('widget--promotion-block-bg', #{colors.get(neutral, 700)});
	@include rgbfy('widget--promotion-block-text', #{colors.get(neutral, 700)});
	@include rgbfy('widget--secondary-bg', #{colors.get(neutral, 150)});
	@include rgbfy('widget--secondary-bg-darken-3', #{colors.get(neutral, 300)});
	@include rgbfy('widget--selection-badge-before-border', #{colors.get(red, 600)});
	@include rgbfy('widget--selection-badge-before-gr-from', #{colors.get(red, 300)});
	@include rgbfy('widget--selection-badge-before-gr-to', #{colors.get(red, 500)});
	@include rgbfy('widget--selection-badge-bg', #{colors.get(red, 500)});
	@include rgbfy('widget--selection-badge-inactive-bg', #{colors.get(red, 600)});
	@include rgbfy('widget--selection-badge-inactive-text', #CA4646);
	@include rgbfy('widget--selection-badge-text', #{colors.get(pink, 600)});
	@include rgbfy('widget--selection-deduction-bg', #{colors.get(red, 400)});
	@include rgbfy('widget--selection-deduction-text', #{colors.get(neutral, 100)});
	@include rgbfy('widget--selection-item-bg', #{colors.get(red, 600)});
	@include rgbfy('widget--selection-item-bg-hover', #{colors.get(red, 500)});
	@include rgbfy('widget--shadow', #{colors.get(neutral, 900)});
	@include rgbfy('widget--title', #{colors.get(neutral, 700)});
	@include rgbfy('first-login--text', $color-text);
	@include rgbfy('first-login--title', $color-text-muted);
	@include rgbfy('first-login--title-bold', $color-primary);
	@include rgbfy('first-login--step-text-color', $color-primary);
	@include rgbfy('first-login--icon-color', $color-primary-active);
	@include rgbfy('first-login-tutorial--color-1', #687BC4);
	@include rgbfy('first-login-tutorial--color-2', #7BD4C4);

	/* Commonize please. These are used only in Mainstream Design currently */
	@include rgbfy('registration--step', #{colors.get(purple, 500)});
	@include rgbfy('registration--step-active', #{colors.get(neutral, 100)});
	@include rgbfy('registration--step-active-bg', #{colors.get(purple, 700)});
	@include rgbfy('registration--step-active-border', #{colors.get(purple, 700)});
	@include rgbfy('registration--step-border', #{colors.get(purple, 500)});
	@include rgbfy('registration--step-text', #{colors.get(purple, 500)});
	@include rgbfy('registration--white', #{colors.get(neutral, 100)});
	@include rgbfy('registration--link', $color-link);
	@include rgbfy('registration--input-border',$color-border);
	@include rgbfy('registration--input-text', #{colors.get(purple, 700)});
	@include rgbfy('registration--input-bg',$color-background-container);
	@include rgbfy('registration--error', #{colors.get(alert, progress_500)});
	@include rgbfy('registration--icon-warning', #{colors.get(alert, progress_500)});
	@include rgbfy('registration--valid', #{colors.get(alert, success_600)});
	@include rgbfy('registration--header-border-one', #00CCCC); // These are not in the palette
	@include rgbfy('registration--header-border-two', #FFE457);
	@include rgbfy('registration--account-type-bg', $color-primary-1);
	@include rgbfy('registration--progress-bar', $color-background);
	@include rgbfy('registration--form--dropdown--item-hover-background', $color-background-container);
	@include rgbfy('registration--form--dropdown--item-hover-text', $color-primary);
	@include rgbfy('registration--snapshot-grid', $turquise);
	@include rgbfy('registration--snapshot-box-header', #{colors.get(neutral, 100)});
	@include rgbfy('main-container--background', #{colors.get(neutral, 400)});
}
